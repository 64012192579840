import { Map, WithMapReady } from 'components/Map/Map'
import { usePolygonCentroids, usePolygons } from 'hooks/polygons'
import { HighwayPolygons } from 'components/Map/HighwayPolygons'
import { useHighwayForecasts } from 'hooks/highwayForecasts'

export const HighwayMap = ({ groupID, onScreenshot }) => {
    const { highwayForecasts } = useHighwayForecasts()
    const polygons = usePolygons()
    const labelsGeoJson = usePolygonCentroids()
    // Remove from labelsGeoJson the ones that don't have a corresponding highwayForecast
    const activePolygons = highwayForecasts.map((forecast) => forecast.polygons).flat()
    const filteredLabelsGeoJson = {
        ...labelsGeoJson,
        features: labelsGeoJson.features.filter((feature) =>
            activePolygons.find((polygonId) => polygonId === feature.properties.id)
        ),
    }

    if (!highwayForecasts || !polygons) {
        return null // Do the null check here so that props are not empty when passed to the map
    }

    return (
        <Map enforceLightStyle>
            <WithMapReady>
                <HighwayPolygons
                    products={highwayForecasts}
                    groupID={groupID}
                    polygons={polygons}
                    onScreenshot={onScreenshot}
                    labelsGeoJson={filteredLabelsGeoJson}
                />
            </WithMapReady>
        </Map>
    )
}
