import moment from 'moment'

import { createEmptyProduct } from './Product'
import { DEFAULT_VALUE_CONFIDENCE, DEFAULT_VALUE_HWY_HAZARD, DEFAULT_VALUE_REVIEW } from './Constants'
import { getEnvTimes } from './Product'

export const createEmptyHighwayForecast = (locale = 'en') => {
    const { expiryENV } = getEnvTimes()

    return {
        ...createEmptyProduct('highwayforecast', locale),
        issueDateTime: moment(),
        expiryDateTime: moment(new Date())
            .add(7, 'days')
            .set({ hour: expiryENV[0] || 16, minute: expiryENV[1] === undefined ? 30 : expiryENV[1] }),
        weatherSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        snowpackSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheProblems: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i }
            }),
        },
        hazard: DEFAULT_VALUE_HWY_HAZARD,
        confidence: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            }),
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        review: {
            status: DEFAULT_VALUE_REVIEW,
            notes: '',
        },
        isTranslated: false,
    }
}

export const MITIGATION_OUTLOOK_OPTIONS = ['unlikely', 'possible', 'likely']

export const createEmptyHighwayDiscussion = (locale = 'en', issueDate = new Date()) => {
    const { expiryENV } = getEnvTimes()

    return {
        ...createEmptyProduct('highwaydiscussion'),
        issueDateTime: moment(),
        expiryDateTime: moment(new Date())
            .add(7, 'days')
            .set({ hour: expiryENV[0] || 16, minute: expiryENV[1] || 30 }),
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        operationalRecommendations: {
            [locale]: '',
        },
        mitigationRecommendations: {
            [locale]: '',
        },
        mitigationOutlook: createMOArray(issueDate),
    }
}

export const createEmptyMitigation = () => {
    return createEmptyProduct('mitigation')
}

export const createMOArray = (issueDate) => {
    return Array.from({ length: 7 }).map((_day, index) => {
        const date = moment(issueDate).add(index, 'days').format('YYYY-MM-DD')
        return { Day: date, Outlook: MITIGATION_OUTLOOK_OPTIONS[0] }
    })
}
