import { useEffect, useState } from 'react'

import { Alert, Button, Card, Col, Row, Select, Space } from 'antd'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { ImageFormSet } from 'components/Product/ImageUpload/ImageFormSet'
import { SmsCardTitle } from 'components/Product/SmsCardTitle'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import {
    addTranslationToField,
    copyHeadlineToSms,
    removeTranslationFromField,
    updateField,
} from 'components/TextField/productHelpers'
import messages from 'services/intl/messageDefinitions'
import { useDateFormat } from 'stores/UserStore'
import { formatDate } from 'utils/Date'
import { MITIGATION_OUTLOOK_OPTIONS } from 'utils/Highways'

export const EditContentForm = ({ toggleContentEdit, item, apiPut }) => {
    const dateFormat = useDateFormat()

    const forecasterSet = item.userID
    const [updateProduct, setUpdateProduct] = useState(false)
    const [render, setRender] = useState(true)
    const [SmsField, setSmsField] = useState(null)

    const generateHandler = (field, mode) => (language, value) => {
        setUpdateProduct({ field, mode, language, value })
    }

    const handleHeadlineChange = generateHandler(['communications', 'headline'], 'update')
    const handleAddHeadlineTranslation = generateHandler(['communications', 'headline'], 'add')
    const handleRemoveHeadlineTranslation = generateHandler(['communications', 'headline'], 'remove')
    const handleSmsChange = generateHandler(['communications', 'sms'], 'update')
    const handleAddSmsTranslation = generateHandler(['communications', 'sms'], 'add')
    const handleRemoveSmsTranslation = generateHandler(['communications', 'sms'], 'remove')
    const handleMessageChange = generateHandler('message', 'update')
    const handleAddMessageTranslation = generateHandler('message', 'add')
    const handleRemoveMessageTranslation = generateHandler('message', 'remove')
    const handleCopyHeadlineToSms = generateHandler(null, 'copyHeadlineToSms')
    const handleOperationalRecommendationsChange = generateHandler('operationalRecommendations', 'update')
    const handleAddOperationalRecommendationsTranslation = generateHandler('operationalRecommendations', 'add')
    const handleRemoveOperationalRecommendationsTranslation = generateHandler('operationalRecommendations', 'remove')
    const handleMitigationRecommendationsChange = generateHandler('mitigationRecommendations', 'update')
    const handleAddMitigationRecommendationsTranslation = generateHandler('mitigationRecommendations', 'add')
    const handleRemoveMitigationRecommendationsTranslation = generateHandler('mitigationRecommendations', 'remove')

    useEffect(() => {
        if (updateProduct) {
            setUpdateProduct(false)

            const { field, mode, language, value } = updateProduct
            let updatedProduct
            switch (mode) {
                case 'update':
                    updatedProduct = updateField(item, field, language, value)
                    break
                case 'add':
                    updatedProduct = addTranslationToField(item, field, language)
                    setRender(true)
                    break
                case 'remove':
                    updatedProduct = removeTranslationFromField(item, field, language)
                    setRender(true)
                    break
                case 'copyHeadlineToSms':
                    updatedProduct = copyHeadlineToSms(item)
                    setRender(true)
                    break
                default:
                    break
            }
            apiPut(updatedProduct)
        }
    }, [updateProduct, item])

    // Need to control when to render the SmsField so that it doesn't rerender every time text is updated
    // but does update when we add/remove new translations or copy from headline.
    const generateSmsField = () => {
        return (
            <TranslatedTextField
                handleChange={handleSmsChange}
                handleAddTranslation={handleAddSmsTranslation}
                handleRemoveTranslation={handleRemoveSmsTranslation}
                translations={item.communications.sms}
                plainText={true}
                characterLimit={140}
            />
        )
    }

    useEffect(() => {
        if (render && Object.keys(item).length > 0) {
            setSmsField(generateSmsField())
            setRender(false)
        }
    }, [item])

    const handleMOChange = (value, day) => {
        const oldMitigationOutlook = item.mitigationOutlook
        const updatedMitigationOutlook = oldMitigationOutlook.map((outlook) => {
            if (outlook.Day === day) {
                return { Day: outlook.Day, Outlook: value }
            }
            return outlook
        })

        const updatedProduct = {
            ...item,
            mitigationOutlook: updatedMitigationOutlook,
        }
        apiPut(updatedProduct)
    }

    return (
        <form>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <h1 style={styles.title}>
                    <FormattedMessage
                        {...messages.editProductContent}
                        values={{
                            product: item.name,
                        }}
                    />
                </h1>
                {!forecasterSet && (
                    <Alert
                        type="error"
                        message={
                            <FormattedMessage
                                id="setForecasterWarning"
                                defaultMessage="Please select a forecaster in the Setup before editing this product’s content"
                                description="Product content edit form"
                            />
                        }
                    />
                )}
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                        pointerEvents: forecasterSet ? 'inherit' : 'none',
                        opacity: forecasterSet ? 1 : 0.5,
                    }}
                >
                    <Card title={<FormattedMessage {...messages.headline} />}>
                        <TranslatedTextField
                            handleChange={handleHeadlineChange}
                            handleAddTranslation={handleAddHeadlineTranslation}
                            handleRemoveTranslation={handleRemoveHeadlineTranslation}
                            translations={item.communications.headline}
                            plainText={false}
                            characterLimit={280}
                        />
                    </Card>
                    <Card title={<SmsCardTitle handleClick={handleCopyHeadlineToSms} />}>{!render && SmsField}</Card>
                    {item.message && (
                        <Card title={<FormattedMessage {...messages.message} />}>
                            <TranslatedTextField
                                handleChange={handleMessageChange}
                                handleAddTranslation={handleAddMessageTranslation}
                                handleRemoveTranslation={handleRemoveMessageTranslation}
                                translations={item.message}
                                plainText={false}
                            />
                        </Card>
                    )}
                    {item.operationalRecommendations && (
                        <Card title={<FormattedMessage {...messages.operationalRecommendations} />}>
                            <TranslatedTextField
                                handleChange={handleOperationalRecommendationsChange}
                                handleAddTranslation={handleAddOperationalRecommendationsTranslation}
                                handleRemoveTranslation={handleRemoveOperationalRecommendationsTranslation}
                                translations={item.operationalRecommendations}
                                plainText={false}
                            />
                        </Card>
                    )}
                    {item.mitigationRecommendations && (
                        <Card title={<FormattedMessage {...messages.mitigationRecommendations} />}>
                            <TranslatedTextField
                                handleChange={handleMitigationRecommendationsChange}
                                handleAddTranslation={handleAddMitigationRecommendationsTranslation}
                                handleRemoveTranslation={handleRemoveMitigationRecommendationsTranslation}
                                translations={item.mitigationRecommendations}
                                plainText={false}
                            />
                        </Card>
                    )}
                    <Card title={<FormattedMessage {...messages.media} />} key="media">
                        <ImageFormSet product={item} apiPut={apiPut} />
                    </Card>
                    {item.mitigationOutlook && (
                        <Card title={<FormattedMessage {...messages.mitigationOutlook} />}>
                            {item.mitigationOutlook.map((field) => {
                                const label = `${formatDate(moment(field.Day), dateFormat)} (${moment(field.Day).format(
                                    'ddd'
                                )})`

                                return (
                                    <Row key={field.Day}>
                                        <Col span={10} style={styles.label}>
                                            <p style={styles.labelText}>{label}</p>
                                        </Col>
                                        <Col span={14} style={styles.selectCol}>
                                            <Select
                                                style={styles.select}
                                                size={'large'}
                                                bordered={false}
                                                listHeight="100%"
                                                onChange={(val) => handleMOChange(val, field.Day)}
                                                value={field.Outlook}
                                            >
                                                {MITIGATION_OUTLOOK_OPTIONS.map((option) => (
                                                    <Select.Option key={`${field.day}-${option}`} value={option}>
                                                        {option.value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Card>
                    )}
                </Space>
                <div style={styles.buttonContainer}>
                    <Button type="primary" onClick={toggleContentEdit}>
                        <FormattedMessage {...messages.closeEditing} />
                    </Button>
                </div>
            </Space>
        </form>
    )
}

const styles = {
    buttonContainer: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right',
    },
    title: {
        fontWeight: 300,
    },
    label: {
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid var(--grey-light)',
        borderLeft: 'none',
    },
    labelText: {
        marginBottom: 0,
    },
    selectCol: {
        borderTop: '1px solid var(--grey-light)',
        borderBottom: '1px solid var(--grey-light)',
    },
    formItem: {
        marginBottom: 0,
    },
    select: {
        width: '100%',
    },
}
